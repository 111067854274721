import React, { useEffect, useState, useCallback } from 'react';

import './ProcessingManager.less';
import OptionBarItemContainer from 'src/Components/Common/OptionBarItemContainer';
import BaseButton from 'src/Components/Common/Buttons/BaseButton';
import CreateDataForm from 'src/Components/CreateData/CreateDataForm';
import api from 'src/api';
import { useStore } from 'src/store';
import InputPro from 'src/Components/Common/InputPro/InputPro';
import { debounce } from 'lodash'; // Import lodash for debouncing
import BaseModal from 'src/Components/Common/Modal/BaseModal';
import ProgressBar from '@ramonak/react-progress-bar';
import SelectPro from 'src/Components/Common/SelectPro/SelectPro';
import utils from 'src/utils';

function ProcessingManager({ onClickClose }) {
  const store = useStore();
  const [search, setSearch] = useState('');
  const [stopProcessing, setStopProcessing] = useState(null);
  const [uploadMode, setUploadMode] = useState(false);
  const [loading, setLoading] = useState(true);
  const [processingJobs, setProcessingJobs] = useState([]);
  const [finalJobsList, setFinalJobsList] = useState([]);
  const [noJobs, setNoJobs] = useState(false);
  const [cancelId, setCancelId] = useState(null);
  const [selectedJob, setSelectJob] = useState(null);

  const processingOptions = [
    { value: 'all', label: 'All' },
    { value: 'done', label: 'Done' },
    { value: 'processing', label: 'Processing' },
    { value: 'failed', label: 'Failed' },
    { value: 'canceled', label: 'Canceled' },
  ];
  const sortOptions = [
    { value: 'newest', label: 'Newest' },
    { value: 'oldest', label: 'Oldest' },
  ];

  const [selectedFilter, setSelectedFilter] = useState(processingOptions[0].value);
  const [selectedSort, setSelectedSort] = useState(sortOptions[0].value);

  const progressBarColor = status => {
    if (status === 'DONE') {
      return '#7C7655';
    } else if (status === 'PROCESSING') {
      return '#D09869';
    } else if (status === 'FAILED') {
      return '#E48693';
    } else if (status === 'CANCELED') {
      return 'gray';
    }
    return '#7C7655';
  };

  const psudoJobs = Array.from({ length: 50 }, () => ({
    dataset_name: '---',
    created_at: '---',
    coordinate_system: '---',
  }));

  const fetchProcessingJobs = useCallback(async () => {
    try {
      const res = await api.call('/processing/list', {});
      console.log('Updating processing jobs...');
      if (res.success) {
        const sortedJobs =
          selectedSort == 'newest'
            ? res.success.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
            : res.success.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        const filteredJobs = sortedJobs.filter(
          job => job.channel_name === store.session.channel.name
        );
        if (filteredJobs.length === 0) {
          setNoJobs(true);
        } else {
          setProcessingJobs(filteredJobs);
          Search(filteredJobs);
        }
      } else {
        setNoJobs(true);
      }
    } catch (error) {
      console.error('Error fetching processing jobs:', error);
      setNoJobs(true);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchProcessingJobs();
    const intervalId = setInterval(fetchProcessingJobs, 5000);
    return () => clearInterval(intervalId);
  }, [fetchProcessingJobs]);

  const Search = useCallback(
    debounce((searchValue, jobs) => {
      if (!jobs.length) return;
      if (!searchValue) {
        setFinalJobsList(jobs);
        return;
      }
      const searchJobs = jobs.filter(job =>
        job.dataset_name?.toLowerCase().includes(searchValue.toLowerCase())
      );
      setFinalJobsList(searchJobs);
    }, 100),
    []
  );

  useEffect(() => {
    Search(search, processingJobs);
  }, [search, processingJobs, Search]);

  const handleSearchChange = value => {
    setSearch(value);
  };

  const Progress = processing_tasks => {
    const progress = processing_tasks.filter(task => task.task_status === 'DONE').length;
    let percentage = (progress / processing_tasks.length) * 100;
    return isNaN(percentage) ? '?' : percentage.toFixed(0);
  };

  const InProgress = processing_tasks => {
    const progress = processing_tasks.filter(task => task.task_status === 'DONE').length;
    return (progress / processing_tasks.length) * 100 < 100;
  };

  async function stopProcessingJob(job) {
    const res = await api.call('/processing/stop', { id: job.processing_job_id });
    setStopProcessing(null);
    setCancelId(job.processing_job_id);
  }

  const sortOrder = selectedSort === 'newest' ? 'desc' : 'asc';

  const sortedJobs = finalJobsList.sort((a, b) => {
    const dateA = new Date(a.created_at);
    const dateB = new Date(b.created_at);
    return sortOrder === 'desc' ? dateB - dateA : dateA - dateB;
  });

  const displayJobs =
    selectedFilter !== 'all'
      ? sortedJobs.filter(job => job.job_status.toLowerCase() === selectedFilter?.toLowerCase())
      : sortedJobs;

  const job = displayJobs.find(job => job.processing_job_id === cancelId);
  if (job) job.job_status = 'CANCELED';

  //Time estimations
  function elapsedTime(created_at) {
    return Date.now() - new Date(created_at).getTime();
  }

  function estimateTimeLeft(created_at, processing_tasks) {
    const elapsed_time = elapsedTime(created_at);

    let total_tasks = processing_tasks.length;
    let done_tasks = 0;
    processing_tasks.forEach(task => {
      if (task.task_status === 'DONE') {
        done_tasks++;
      }
    });

    let remaining_tasks = total_tasks - done_tasks;
    if (done_tasks === 0) return 'Estimate Time Left: Calculating...';

    let average_time_per_task = elapsed_time / done_tasks;
    let estimated_time_left = average_time_per_task * remaining_tasks;

    if (done_tasks < 1) {
      return 'Estimate Time Left: Calculating...';
    } else if (estimated_time_left < 3600000) {
      let estimated_time_left_minutes = estimated_time_left / (1000 * 60);
      return (
        <>
          Estimated Time Left:{' '}
          <b style={{ color: '#62a738' }}>{estimated_time_left_minutes.toFixed(0)} Minutes</b>
        </>
      );
    } else {
      let estimated_time_left_hours = estimated_time_left / (1000 * 60 * 60);
      return (
        <>
          Estimated Time Left:{' '}
          <b style={{ color: '#D09869' }}>{estimated_time_left_hours.toFixed(0)} Hours </b>
        </>
      );
    }
  }
  //

  if (selectedJob) {
    return (
      <OptionBarItemContainer
        title={'Back'}
        onClickBack={() => {
          setSelectJob(null);
          setSelectedFilter(processingOptions[0].value);
          setSelectedSort(sortOptions[0].value);
        }}
        isExpandButtonShowed
        icon="/icons/upload_cloud.svg"
        isIconShowed={true}
        isShowingChannel={true}
        isArrowButtonShowed={true}
      >
        <div className="selected_processing_job_container">
          <div style={{ fontSize: '50px' }}>{'Name: ' + selectedJob.dataset_name.slice(0, -5)}</div>
          <div style={{ color: progressBarColor(selectedJob.job_status) }}>
            {'Status: ' + selectedJob.job_status}
          </div>
          <div>{'Channel Name: ' + selectedJob.channel_name}</div>
          <div>{'Coordinate System: ' + selectedJob.coordinate_system}</div>
          <div>{'Start Time: ' + utils.formatDate(selectedJob.start_time)}</div>
          <div>{'Created at: ' + utils.formatDate(selectedJob.created_at)}</div>
          <div>{'Images: ' + selectedJob.number_of_images}</div>
          <div>{'Progress: ' + Progress(selectedJob.processing_tasks) + '%'}</div>
        </div>
      </OptionBarItemContainer>
    );
  }

  return (
    <OptionBarItemContainer
      title="Processing Manager"
      onClickBack={
        uploadMode
          ? () => {
              setUploadMode(false);
              fetchProcessingJobs();
            }
          : onClickClose
      }
      isExpandButtonShowed
      icon="/icons/upload_cloud.svg"
      isIconShowed={true}
      isShowingChannel={true}
      isArrowButtonShowed={true}
    >
      {stopProcessing && (
        <BaseModal
          title={'Stop processing: ' + stopProcessing.dataset_name}
          rightButton="Stop"
          onClickCancel={() => setStopProcessing(null)}
          onClickRightButton={() => {
            api.call(`/processing/stop`, { id: stopProcessing.processing_job_id });
            stopProcessingJob(stopProcessing);
          }}
        />
      )}

      {!uploadMode && (
        <>
          <div style={{ color: 'var(--gold)', fontWeight: 'bold' }}>
            Search By Name:{' '}
            <InputPro
              onChange={e => handleSearchChange(e.target.value)}
              defaultValue={search}
              bindValue={setSearch}
            />
          </div>
          <div className="processing_selections">
            <SelectPro
              name="Filter"
              defaultValue={processingOptions[0]}
              options={processingOptions}
              onChange={e => setSelectedFilter(e.value)}
            />
            <SelectPro
              name="Sort"
              defaultValue={sortOptions[0]}
              options={sortOptions}
              onChange={e => setSelectedSort(e.value)}
            />
          </div>
        </>
      )}

      {uploadMode ? (
        <CreateDataForm />
      ) : noJobs ? (
        <div className="noJobs">No Jobs Found</div> //Initialization branch
      ) : (
        <div className="processing_manager_container">
          {loading
            ? psudoJobs.map((job, index) => (
                <div
                  key={index}
                  className="processing_job psudo"
                  style={{ animationDelay: `${index * 0.02}s` }}
                ></div>
              ))
            : displayJobs.map((job, index) => (
                <div
                  key={index}
                  className={`processing_job hoverenabled ${job.job_status.toLowerCase()}`}
                  onClick={() => setSelectJob(job)}
                >
                  <div className="job_header">
                    <div style={{ maxWidth: '528px', overflow: 'hidden' }}>
                      {job.dataset_name?.slice(0, -5)}
                    </div>
                    <div style={{ fontSize: '14px' }}>{job.job_status}</div>
                  </div>
                  {
                    <ProgressBar
                      labelSize="18px"
                      completed={Progress(job.processing_tasks)}
                      borderRadius="4px"
                      customLabel={Progress(job.processing_tasks) + '%'}
                      bgColor={progressBarColor(job.job_status)}
                      height="26px"
                      isLabelVisible={true}
                    />
                  }
                  <div className="processing_image_count">
                    {job.number_of_images}
                    <img style={{ filter: 'brightness(0.4)' }} src="/icons/image.svg"></img>
                  </div>
                  <div className="processing_created_at">
                    <div>{'Start Time: ' + utils.formatDate(job.created_at)}</div>
                    {job.job_status == 'PROCESSING' && (
                      <div>{estimateTimeLeft(job.created_at, job.processing_tasks)}</div>
                    )}
                  </div>
                  {InProgress(job.processing_tasks) && job.job_status != 'DONE' && (
                    <BaseButton
                      buttonType="DELETE"
                      style={{ marginTop: '15px', marginRight: '0' }}
                      onClick={() => setStopProcessing(job)}
                    >
                      Stop
                    </BaseButton>
                  )}
                </div>
              ))}
        </div>
      )}

      {!uploadMode && (
        <>
          <BaseButton
            style={{ gap: '15px' }}
            additionalClassName="continue_button"
            onClick={() => {
              setUploadMode(true);
            }}
          >
            Add Job
            <img style={{ filter: 'brightness(3)' }} src="/icons/upload_cloud.svg" />
          </BaseButton>
          <BaseButton
            style={{ gap: '15px' }}
            disabled
            title="Coming soon!"
            additionalClassName="cancel_button"
            onClick={() => {
              setUploadMode(true);
            }}
          >
            Upload
            <img style={{ filter: 'brightness(3)' }} src="/icons/upload_cloud.svg" />
          </BaseButton>
        </>
      )}
    </OptionBarItemContainer>
  );
}

export default ProcessingManager;
