import React from 'react';
import './Login.less';
import { Button } from 'primereact/button';

function Login(props) {
  return (
    <div className="main__container">
      <div
        className="login-background"
        style={{ backgroundImage: "url('/login_background.jpg')" }}
      />
      <div className="Login__container">
        <img
          src="/svarmi.svg"
          style={{ width: 40, marginBottom: 50 }}
          alt="logo"
          className="logo"
        ></img>

        <h1>Welcome Back</h1>

        {props.error && props.error.message !== 'Login required' && (
          <div style={{ color: 'red', maxWidth: 600, overflow: 'auto' }}>{props.error.message}</div>
        )}

        <div className="actions">
          <Button 
            type="submit" 
            label="Log In" 
            onClick={props.doLogin}
            outlined />
        </div>

        <img
          src="/SVARMI-Logo_Datact-Horiz.svg"
          style={{ width: 140, marginTop: 70 }}
          alt="logo"
          className="logo"
        ></img>

        <div className="conditions">
          <div>
            <a href="https://www.svarmi.com/terms-conditions">Terms & Conditions</a>
          </div>
          <div>
            <a href="https://www.svarmi.com/privacy-cookie-policy">Privacy & Cookie Policy</a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
