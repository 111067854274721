import React, { useState, useEffect } from 'react';
import './Home.less';

/* Components */
import Modal from 'src/Common/Modal';
import Settings from 'src/Settings/Settings';
import GeoMap from 'src/Map/GeoMap';
import Menu from 'src/Menu';
import Slider from 'src/Timeslider/Slider';
import BatchPinUpload from 'src/Pins/BatchPinUpload';
import SinglePinUpload from 'src/Pins/SinglePinUpload';
import { UploadFlowWrapper } from 'src/ProcessingManager/UploadFlow';
import LoadingScreen from 'src/LoadingScreen';
import Analytics from 'src/Analytics';
import Assets from 'src/Assets';
import Help from 'src/Help/Help';
import SatelliteImagery from 'src/SatelliteImagery/SatelliteImagery';

import { transform, transformExtent, toLonLat } from 'ol/proj';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import api from 'src/api';
import utils from 'src/utils';
import { MultiPolygon } from 'ol/geom';
import { getArea } from 'ol/extent';
import Toolbar from 'src/Toolbar';
import { useStore, ref } from 'src/store';
import LayersPanel from 'src/Widgets/LayersPanel';
import DataManager from 'src/DataManager/DataManager';
import { Button } from 'primereact/button';

import ProcessingManager from 'src/ProcessingManager/ProcessingManager';
import DataManagerControls from './DataManager/DataManagerControls';
//import BaseModal from 'src/Common/Modal/BaseModal';

function Home() {
  const [activate3D, setActivate3D] = useState(false);
  const [assetOverlap, setAssetOverlap] = useState(null);
  const [basemap, setBasemap] = useState('light');
  const [curtain, setCurtain] = useState(false);
  const [curtainMessageTimeout, setCurtainMessageTimeout] = useState(null);
  const [currentLocation, setCurrentLocation] = useState([0, 0]);
  const [currentLocationRadius, setCurrentLocationRadius] = useState(null);
  const [fullscreen, setFullscreen] = useState(false);
  const [layersLoading, setLayersLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [location, setLocation] = useState(null);
  const [measure, setMeasure] = useState(null);

  //area hovers
  const [aois, setAois] = useState(null);
  const [monitoringAreas, setMonitoringAreas] = useState(null);
  const [assetFootprints, setAssetFootprint] = useState(null);
  const [dataframes, setDataframes] = useState(null);

  const [AssetsAsset, setAssetsAsset] = useState(null);
  const [errorPopup, setErrorPopup] = useState(null);
  const [range, setRange] = useState([]);
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [selectedPins, setSelectedPins] = useState({});
  const [filteredPins, setFilteredPins] = useState(null); //These are sent to the map!
  const [waterUsage, setWaterUsage] = useState(null);
  const [energyUsage, setEnergyUsage] = useState(null);
  const [teamInfo, setTeamInfo] = useState(null);
  const [aoiPreview, setAoiPreview] = useState(null);
  const [hoveredOverlay, setHoveredOverlay] = useState(null);
  const [addSinglePinLocation, setAddSinglePinLocation] = useState(null);
  const [clickedPin, setClickedPin] = useState(null);
  const [refreshHotlinkEnabled, setRefreshHotlinkEnabled] = useState(false);

  const [wrongLink, setWrongLink] = useState({ team: null, channel: null, notInTeam: false });
  const store = useStore();

  useEffect(() => {
    initData();
  }, []);

  useEffect(() => {
    setAoiPreview(store.ui.aoiPreview);
  }, [store.ui.aoiPreview]);

  useEffect(() => {
    setHoveredOverlay(store.ui.hoveredOverlay);
  }, [store.ui.hoveredOverlay]);

  useEffect(() => {
    console.log(dataframes);
  }, [dataframes]);

  useEffect(() => {
    store.ui.map?.state?.olmap?.updateSize();
  }, [store.ui.menuOpen, store.ui.menu.currentTab]); 

  const initData = async () => {
    setLoading(true);
    setWrongLink(null);

    store.ui.setMapLocation = updateLocation;
    try {
      const responseTeamInfo = await api.call('/team/details');
      await setChannel(responseTeamInfo.success);
      //Find a suitable channel
      await Promise.all([refreshDatasets(), refreshTeamInfo()]);
      const allChannels = await api.call('/channel/list');

      store.data.allChannels = allChannels?.success;

      await Promise.all([refreshAssets(), refreshLayers(), refreshPins()]);
      Analytics.updateInfo();
      setLoading(false);

      jumpToStartingView();
      //handleHotlink()

      const teamMetrics = await api.call(`/asset/metrics`);
      if(teamMetrics.success)
        store.data.teamMetrics = teamMetrics.success;
      else
        store.data.teamMetrics = -1;
    } catch (error) {
      console.info(error);
      setLoading(false);
      setErrorPopup('An error occurred: ' + error.toString());
      Analytics.reportClientError(error);
    }
  };

  async function jumpToStartingView() {
    // First try to follow the hotlink (if present)
    if (await handleHotlink()) return;

    // If there was no hotlink, try to go to last seen location
    // (except in sharing mode where we only care about the dataset being shared, not where you're coming from)
    let lastViewExtent = null;
    if (store.session.team?.plan !== 'temporary') {
      try {
        const extent = JSON.parse(localStorage.getItem('lastViewExtent'));
        if (extent && !extent.some(isNaN)) lastViewExtent = extent;
      } catch (e) {
        console.error(e);
      }
    }

    // If no last seen location set, try to zoom on latest valid uploaded layer instead
    const latestValidLayerExtent = (store.data.layers||[]).slice().reverse().find(l => l.extent_3857)?.extent_3857;

    // Otherwise just go to the default zoomed out view
    const worldExtent = [-16945783, -6966165, 20663680, 9979618];

    const jumpTo = {
      extent_3857: lastViewExtent || latestValidLayerExtent || worldExtent,
      padding_px: 0,
      duration: 0,
    };

    setTimeout(() => store.ui.map.state.olmap.jumpTo({ ...jumpTo }), 1000);
  }

  async function handleHotlink() {
    // Follows hotlinks such as: https://dev.datact.svarmi.com/#/svarmi/main/pin/348/comment/41

    const [, team, channel, type, id, ...extra] = window.location.hash.split('/');
    console.log(window.location.hash, { team, channel, type, id, extra });

    // After parsing, immediately eat up the hash, so we don't end up with a stale url, or worse, an infinite loop
    // Similarly, do not follow live-update URLs once we start generating them (would cause an infinite loop)
    if (refreshHotlinkEnabled) return;
    window.location.hash = '';
    setRefreshHotlinkEnabled(true);

    if(!team || !channel) return console.log("[Hotlink] Incomplete or empty hotlink, skipping");
    
    if(store.session.team.name !== team) { // .team_id
      //const res = await api.call('/user/details', { email: store.session.user.email })
      //console.log(res)
      
      /*if(res.user.teams.find(t => t.name === team)) {
        setWrongLink({team: team, channel: null, notInTeam : false});
      }
      else {
        setWrongLink({team: team, channel: channel, notInTeam: true});
      }*/
      console.log("[Hotlink] Team in hotlink is not current team, switching (for now, just abort)");
      return;
    }

    console.log('[Hotlink] Team in hotlink matches current team');

    if(store.session.channel.name !== channel) { // .channel_id
      console.log("[Hotlink] Channel in hotlink is not current channel, switching");
      const targetChannel = store.data.channels.find(c => c.name === channel);
      if(!targetChannel) {
        setWrongLink({team: null, channel: channel, notInTeam: false});
        return console.log("[Hotlink] Hotlinked channel not found for this user, aborting");
      }
      store.session.channel = { channel_id: targetChannel.channel_id, name: targetChannel.name };
      await initData();
    }

    console.log('[Hotlink] Channel in hotlink matches current channel');

    if (type === 'pin') {
      console.log('[Hotlink] Jumping to hotlinked pin');
      await new Promise(r => setTimeout(r, 100));
      const pin = store.data.pins.find(p => p.pin_id === parseInt(id));
      store.ui.map.state.clickedPin = pin;

      if (extra[0] === 'comment') {
        store.ui.map.state.highlightedCommentId = parseInt(extra[1]);
      }

      store.ui.map.state.olmap.jumpTo({ point_3857: pin.geog_3857 });

      return true;
    }

    if (type === 'asset') {
      console.log('[Hotlink] Jumping to hotlinked asset');
      const asset = store.data.assets.find(a => a.asset_id === parseInt(id));
      // || store.data.assets.find(a => a.name === id)
      showAssets(asset);
      return true;
    }

    if (type === 'raster') {
      console.log('[Hotlink] Jumping to hotlinked raster');
      const layer = store.data.layers.find(l => l.optimized_raster_id === parseInt(id));
      // || store.data.layers.find(l => l.name === id)
      store.ui.map.state.olmap.jumpTo({ extent_3857: layer.extent_3857 });
      return true;
    }

    if (type === 'pointcloud') {
      console.log('[Hotlink] Jumping to hotlinked pointcloud');
      const layer = store.data.layers.find(l =>
        l.dataset.optimized_pointclouds.some(p =>
          p.optimized_pointcloud_id === parseInt(id)
        )
      )
      store.ui.map.state.olmap.jumpTo({ extent_3857: layer.extent_3857, callback: () =>
        setTimeout(() => document.querySelector('.toolbar__container [title="Switch to 3D"]').click(), 100)
      });
      return true;
    }

    if(type === 'view') {
      console.log("[Hotlink] Jumping to hotlinked view coordinates")
      const [lat, lng, zoom] = id.split(',').map(parseFloat);
      
      console.log([lat, lng, zoom])

      // Very skitafix, but directly setting olmap.center and olmap.zoom breaks everything
      // The initialisation sequence is a mess and GeoMap triggers Home which triggers GeoMap etc
      // each with various stale states and props and olmap status.
      // Setting a one sec timeout (which seems to work) and hoping for the best...

      const radius_m = Math.pow(2, 25.85-zoom); // inverse zoom level formula, should get you in the ballpark
      setTimeout(() => {
        store.ui.map.state.olmap.jumpTo({ point_4326: [lng, lat], radius_m, padding_px: 0, duration: 0 });
      }, 1000);
      return true;

    }
  }

  async function updateHotlink() {
    if (!refreshHotlinkEnabled) return;

    //console.log("updateHotlink")
    let extent = store.ui.map?.state?.olmap && store.ui.map.state.olmap.getView().getViewStateAndExtent().extent;
    extent = extent && transformExtent(extent, 'EPSG:3857', 'EPSG:4326');
    extent = extent && extent.map(c => c.toFixed(6)).join(',');

    const i = {
      team: store.session?.team?.name,
      channel: store.session?.channel?.name,
      pin: store.ui.map?.state?.clickedPin?.pin_id,
      asset: selectedAsset?.asset_id,
      location: location,
      viewState: store.ui.map?.state?.olmap?.frameState_?.viewState,
      extent: extent, // "view/-21.5454,,,/"  OR "2d/-21.4343"
      pointcloud: store.ui.map?.state?.mostVisibleLayers,
    };
    //console.log(i)

    // Not just hotlink but update stored lastViewExtent after every pan
    if(extent) localStorage.setItem('lastViewExtent', JSON.stringify(store.ui.map?.state?.olmap?.frameState_?.extent));

    let coords = i.viewState?.center && toLonLat(i.viewState.center)//, 'EPSG:3857', 'EPSG:4326')
    coords = coords && `${coords[1].toFixed(6)},${coords[0].toFixed(6)},${i.viewState.zoom.toFixed(1)}`

    window.location.hash = 
      i.pin ? `/${i.team}/${i.channel}/pin/${i.pin}` :
      i.asset ? `/${i.team}/${i.channel}/asset/${i.asset}` :
      //i.extent ? `/${i.team}/${i.channel}/2d/${i.extent}` :
      coords ? `/${i.team}/${i.channel}/view/${coords}` :
      i.channel ? `/${i.team}/${i.channel}` : `` ;
  }

  async function refreshDatasets() {
    const responseDatasets = await api.call('/dataset/list');
    store.data.datasets = ref(responseDatasets?.success?.datasets || responseDatasets?.success || []);
  }

  async function refreshAssets() {
    if (!store.session.assets_enabled) {
      store.data.assets = ref([]);
      return;
    }
    const responseAssets = await api.call('/asset/list');
    const assets =
      (responseAssets?.success?.assets || responseAssets?.success || []).map(asset => ({
        location_3857:
          asset.location?.length && transform(asset.location, 'EPSG:4326', 'EPSG:3857'),
        geog_3857:
          asset.geog?.length &&
          new MultiPolygon(asset.geog).clone().transform('EPSG:4326', 'EPSG:3857').getCoordinates(),
        footprint_3857:
          asset.footprint?.length &&
          new MultiPolygon(asset.footprint)
            .clone()
            .transform('EPSG:4326', 'EPSG:3857')
            .getCoordinates(),
        ...asset,
      })) || [];
    store.data.assets = ref(assets);
  }

  async function refreshTeamInfo() {
    const responseTeamInfo = await api.call('/team/details');
    const teamInfo = responseTeamInfo?.success;
    const users = teamInfo?.users || [];
    store.data.users = ref(users);
    store.data.teamInfo = ref(teamInfo);
    setTeamInfo(teamInfo);
  }

  async function setChannel(Team) {
    store.data.channels = Team?.channels;
    const current_channel =
      Team?.channels?.find(c => c.channel_id === store.session.channel?.channel_id) ||
      Team?.channels?.find(c => c.channel_id == localStorage.getItem('previous_channel_id')) ||
      Team?.channels?.find(c => c.name === 'main') ||
      Team?.channels[0];
    store.session.channel.channel_id = current_channel.channel_id;
    localStorage.setItem('previous_channel_id', current_channel.channel_id);
    store.session.channel.name = current_channel.name;
    store.session.team.team_id = Team.team_id;
    store.session.assets_enabled = Team.assets_enabled;
    store.session.experimental_enabled = Team.experimental_enabled;
    store.session.wms_config = Team.wms_config;

    store.ui.mapserverUrl = `${process.env.REACT_APP_MAPSERVER_URL}?map=/map/${
      current_channel?.mapfile_id || Team?.mapfile_id
    }.map`;
  }

  async function updateChannels() {
    const responseTeamInfo = await api.call('/team/details');
    console.log(responseTeamInfo);
    await setChannel(responseTeamInfo.success);
    await refreshTeamInfo();
    const allChannels = await api.call('/channel/list');
    store.data.allChannels = allChannels?.success;
  }

  const set3DView = () => {
    if (curtain) hideCurtain();
    store.ui.menu.currentTab = null;
    store.ui.menuOpen = false;
    setActivate3D(activate3D ? false : true);
  };

  const setNewViewBasedOnId = (datasetId, id, type, callback) => {
    console.log(datasetId, id, type);
    if (id && datasetId) {
      // Raster and Pointcloud location lookups are a bit tricky ;
      // DataManager show the *non-opt* rasters and pc list. However what's visible
      // in Datact are the *opt* rasters and pc. As is, there is no guarantee that at
      // the location of a non-opt raster, we will find a corresponding opt raster.
      // Indeed one can have non-opt without opts (only visible in DataManager) as well
      // as opts without non-opts (visible on map but not in DM).
      //
      // They will however match in practice, but so what we do here for consistency is given
      // the *non-opt* id, go up to its parent dataset, and lookup every *opt* raster that has
      // origin_raster_id set to our starting non-opt raster, then use this extent.

      if (type === 'Raster') {
        const dataset = store.data.datasets.find(dataset => dataset.dataset_id === datasetId);

        const optimized_raster = dataset.optimized_rasters.find(r => r.origin_raster_id === id);
        const raster = dataset.rasters.find(r => r.raster_id === id);

        if (!optimized_raster?.geog && !raster?.geog) return false;
        store.ui.map?.state.olmap?.jumpTo({ geog_4326: optimized_raster?.geog || raster.geog });
      }

      if (type === 'Pointcloud') {
        const dataset = store.data.datasets.find(dataset => dataset.dataset_id === datasetId);

        const optimized_pointcloud = dataset.optimized_pointclouds.find(r => r.origin_pointcloud_id === id);
        const pointcloud = dataset.pointclouds.find(r => r.pointcloud_id === id);

        if (!optimized_pointcloud?.geog && !pointcloud?.geog) return false;
        store.ui.map?.state.olmap?.jumpTo({ geog_4326: optimized_pointcloud?.geog || pointcloud.geog, callback });
      }
    }

    if (type === 'Pins') {
      const searchObject = store.data.pins.find(pin => pin.pin_id === id);
      store.ui.map?.state.olmap?.jumpTo({ point_4326: searchObject.geog });
    }

    if (type === 'Asset') {
      const searchObject = store.data.assets.find(asset => asset.asset_id === id);
      store.ui.map?.state.olmap?.jumpTo(
        searchObject.location
          ? { point_4326: searchObject.location }
          : { geog_4326: searchObject.footprint }
      );
    }

    return true;
  };

  const measureArea = () => {
    setMeasure(measure === 'area' ? null : 'area');
  };

  const measureLength = () => {
    setMeasure(measure === 'length' ? null : 'length');
  };

  const disableMeasurement = () => {
    setMeasure(null);
  };

  const onDownloadMap = () => {
    const img = document.querySelector('#map canvas')?.toDataURL('image/png');
    const link = document.createElement('a');
    link.setAttribute('download', 'map_capture.png');
    link.setAttribute('href', img);
    link.click();
  };

  const changeBasemap = newBasemap => {
    setBasemap(
      newBasemap ||
      basemap === 'light' ? 'satellite' :
      basemap === 'satellite' ? 'hybrid' :
      'light'
    );
  };

  const setGrayscale = yes => {
    // Note, updateStyleVariable has to be checked because absent if CanvasRenderingContext
    store.ui.map.state.basemaps?.light?.[0]?.updateStyleVariables?.({ saturation: yes ? -1 : 0 });
  };

  useEffect(() => {
    //setGrayscale(store.ui.assetOverlap.currentOverlap)
    setGrayscale(AssetsAsset);
  }, [
    //store.ui.assetOverlap.currentOverlap
    AssetsAsset,
  ]);

  const onLayersChange = layers => {
    store.ui.rangeFilters = layers;
  };

  const getCurrentLocation = () => {
    if (navigator && navigator.geolocation) {
      if (currentLocationRadius == null) {
        return new Promise(() => {
          navigator.geolocation.getCurrentPosition(
            pos => {
              setCurrentLocation([pos.coords.longitude, pos.coords.latitude]);
              setCurrentLocationRadius(pos.coords.accuracy);
            },
            error => {
              console.info(error);
            }
          );
        });
      } else {
        setCurrentLocation([0, 0]);
        setCurrentLocationRadius(null);
      }
    }
  };

  const toggleFullscreen = () => {
    if (fullscreen) {
      setFullscreen(false);
      document.fullscreenElement && document.exitFullscreen();
    } else {
      setFullscreen(true);
      try {
        !document.fullscreenElement && document.documentElement.requestFullscreen();
      } catch (error) {
        console.info(error);
      }
    }
  };

  if (document.addEventListener) {
    document.addEventListener('webkitfullscreenchange', exitHandler, false);
    document.addEventListener('mozfullscreenchange', exitHandler, false);
    document.addEventListener('fullscreenchange', exitHandler, false);
    document.addEventListener('MSFullscreenChange', exitHandler, false);
  }

  function exitHandler() {
    console.log('Fullscreen mode changed');
    // Fullscreen state check updated for modern browsers
    if (!document.fullscreenElement &&    // Standard syntax
        !document.webkitFullscreenElement && // Chrome, Safari, Opera syntax
        !document.mozFullScreenElement && // Firefox syntax
        !document.msFullscreenElement) { // IE/Edge syntax
      console.log("Exiting fullscreen");
      setFullscreen(false);
    } else {
      console.log('Entering fullscreen');
      setFullscreen(true);
    }
  }

  const showCurtain = () => {
    for (let name in store.ui.rangeFilters) {
      // Starting with a single range and upon opening the curtain feature, we want to subdivide it into two. For instance:
      // - Out of a possible [0, 100] min/max dates ever on the (gray) line
      // - Let's say you've previously moved the two handles to select (blue range) only the datasets between [10, 60]
      // - This split will now create FOUR handles, delineating two separate ranges, one range on the left, one range on the right, with a gap in the middle (for clarity)
      // - Such as: [10,35] [45,60]
      // - But the react-compound-slider library that we use expects it in array form like so: [10, 35, 45, 60]
      const handles = store.ui.rangeFilters[name].selected;
      if (handles.length === 2) {
        const fortyPercentOfRange = Math.floor((handles[1] - handles[0]) * 0.4);
        store.ui.rangeFilters[name].selected = [
          handles[0],
          handles[0] + fortyPercentOfRange,
          handles[1] - fortyPercentOfRange,
          handles[1],
        ];
      }
    }

    // Finally just open and display the message
    setCurtain(true);
    clearTimeout(curtainMessageTimeout);
    setCurtainMessageTimeout(showCurtain && setTimeout(() => setCurtainMessageTimeout(false), 10e3));
  }

  const hideCurtain = () => {
    for (let name in store.ui.rangeFilters) {
      // Conversely starting with a pair of ranges and closing the curtain feature, we want to merge them back together
      // As such: [10, 35, 45, 60] -> [10, 60]
      const handles = store.ui.rangeFilters[name].selected;
      if (handles.length === 4) {
        store.ui.rangeFilters[name].selected = [handles[0], handles[3]];
      }
    }

    setCurtain(false);
  };

  const toggleCurtain = () => (curtain ? hideCurtain() : showCurtain());

  const updateMinMax = (min, max, type) => {
    if (type === 'pins') {
      const tmp = selectedPins;

      tmp.selected = [min.getTime(), max.getTime()];

      tmp['min'] = min;

      tmp['max'] = max;

      setSelectedPins(tmp);
    } else {
      const tmp = store.ui.rangeFilters;

      tmp[type.toLowerCase()].selected = [min.getTime(), max.getTime()];

      tmp[type.toLowerCase()].max = max;

      store.ui.rangeFilters = tmp;
    }
  };

  const updateValuefromSlider = (value, type) => {
    if (!value[0] || !value[1]) {
      return;
    }

    if (type === 'pins') {
      const tmp = selectedPins;
      const tmpPins = [];
      try {
        tmp.selected = value;

        store.data.pins.forEach(item => {
          const time = new Date(item.origin_date || item.created_at).getTime();
          if (time >= value[0] && time <= value[1]) {
            tmpPins.push(item);
          }
        });

        setSelectedPins(tmp);
        setFilteredPins(tmpPins);
      } catch (error) {
        console.info(error);
        Analytics.reportClientError(error);
      }
    } else {
      const tmp = store.ui.rangeFilters;
      try {
        tmp[type.toLowerCase()].selected = value;

        store.ui.rangeFilters = tmp;
        setRange(value);
      } catch (error) {
        console.info(error);
        Analytics.reportClientError(error);
      }
    }
  };

  async function refreshLayers() {
    // Build Layer objects (visible tangible things on the map) from any valid datasets and optimized_rasters info
    const layers = !store.data.datasets?.[0] ? [] :
      store.data.datasets.map(dataset =>
        dataset.optimized_rasters.map(raster => {
          try {
            // Name is last part of the S3 filename
            const name = raster.file_location.match(/[^/]*$/)?.[0];
            const path = `${dataset.name}/${raster.file_location.match(/[^/]*$/)?.[0]}`;

            // Extent is calculated from geog polygon with basic min/max of all coordinates for corners
            const geog = utils.wrapGeog(raster.geog);
            const extent = new MultiPolygon(geog).getExtent();
            const geog_3857 = geog && new MultiPolygon(geog).clone().transform('EPSG:4326', 'EPSG:3857').getCoordinates();
            const extent_3857 = extent && transformExtent(extent, 'EPSG:4326', 'EPSG:3857');

            const area = extent_3857 && getArea(extent_3857);
            const date = raster.origin_date;

            return {
              name,
              path,
              date,
              extent,
              extent_3857,
              geog,
              geog_3857,
              area,
              raster,
              dataset,
            };
          } catch (e) {
            console.trace(e);
            Analytics.reportClientError(e);
          }
        })
      )
      .flat()
      .filter(
        i =>
          i &&
          i.name &&
          i.path &&
          i.dataset.channel_active &&
          i.date &&
          i.extent?.length === 4 &&
          i.raster.viewable
      );

    layers.sort((a, b) => new Date(a.date) - new Date(b.date));

    // Update the range filter state on the Slider and GeoMap (note: I removed support for the shapefile type here, sorry ^_^)
    let minDate = new Date(),
      maxDate = new Date();
    if (layers.length) {
      minDate = new Date(layers[0].date);
      maxDate = new Date(layers[layers.length - 1].date);
    }
    minDate.setUTCHours(0, 0, 0, 0); // Start of the day
    maxDate.setUTCHours(23, 59, 59, 999); // End of the day

    store.ui.rangeFilters = {
      raster: {
        status: true,
        min: minDate,
        max: maxDate,
        selected: [minDate.getTime(), maxDate.getTime()],
      },
    };

    store.data.layers = ref(layers);
  }

  const updateLocation = location => {
    setLocation(location);
  };

  function CloseTabs() {
    store.ui.mapPreviewPin.location = null;
    store.ui.mapPreviewPin.style = null;
    store.ui.menu.currentTab = null;
    store.ui.assetOverlap.currentOverlap = null;
    console.log(store.ui.mapPreviewPin);
  }

  const refreshPins = async () => {
    /* little tmp fix for pins , hard reset to render the slider*/

    const reset = {};
    reset['status'] = false;
    reset['min'] = new Date();
    reset['max'] = new Date();
    setSelectedPins(reset);
    store.data.pins = ref([]);

    try {
      setFilteredPins(null);
      let pins = await api.call('/pin/list', { channel_id: store.session.channel.name });
      let validatedPins = pins.success;

      console.log(teamInfo);
      const b = {};

      if (!validatedPins) {
        b['status'] = false;
        b['min'] = new Date();
        b['max'] = new Date();
        setSelectedPins(b);
      }
      if (validatedPins) {
        validatedPins.forEach(item => {
          const unixDate = new Date(item.origin_date || item.created_at);

          if (!b['min']) {
            b['min'] = unixDate;
          }

          if (!b['max']) {
            b['max'] = unixDate;
          }

          if (!b['status']) {
            b['status'] = true;
          }

          if (b['min'] >= unixDate) {
            b['min'] = new Date(unixDate.setDate(unixDate.getDate() - 1));
          }

          if (b['max'] <= unixDate) {
            b['max'] = new Date(unixDate.setDate(unixDate.getDate() + 1));
          }
        });
      }

      b['selected'] = [b.min.getTime(), b.max.getTime()];

      if (b['max'].getTime() === b['min'].getTime()) {
        b['max'] = new Date(b['max'].setDate(b['max'].getDate() + 2));

        b['min'] = new Date(b['min'].setDate(b['min'].getDate() - 2));

        b['selected'] = [b.min.getTime(), b.max.getTime()];
      }

      if (!validatedPins) {
        b['status'] = false;
      } else {
        b['status'] = true;
      }

      setSelectedPins(b);
      if (validatedPins) store.data.pins = ref(validatedPins);
      if (validatedPins) setFilteredPins(ref(validatedPins));
    } catch (error) {
      console.info(error);
      Analytics.reportClientError(error);
    }
  };

  const setMonAreaAOIToNull = () => {
    setMonitoringAreas(null);
    setAois(null);
    setWaterUsage(null);
    setEnergyUsage(null);
    setSelectedAsset(null);
    setAssetsAsset(null);
    setAssetFootprint(null);
    setAssetOverlap(null);
    store.ui.aoiPreview = null;
    store.ui.assetOverlap.currentOverlap = null;
    store.ui.mapPreviewPin.style = null;
  };

  const setMonAreaAOI = value => {
    const { asset_footprints, monitoring_areas, aois, water_usage, energy_usage } = value;

    setMonitoringAreas(monitoring_areas);
    setAssetFootprint(asset_footprints);
    setAois(aois);
    setWaterUsage(water_usage);
    setEnergyUsage(energy_usage);
    setSelectedAsset(value);
    store.ui.mapPreviewPin.location = null;
    store.ui.aoiPreview = null;
  };

  const showAssets = asset => {
    store.ui.menuOpen = true;
    store.ui.mapPreviewPin.location = null;
    store.ui.menu.currentTab = 'Assets';
    resetAssetOverlaps();
    setAssetsAsset(asset);
  };

  const showAddSinglePin = location => {
    store.ui.menuOpen = true;
    store.ui.menu.currentTab = 'SinglePinUpload';
    if (location) setAddSinglePinLocation(location);
  };

  const closeAssets = () => {
    CloseTabs();
    setMonitoringAreas(null);
    setAois(null);
    setAssetsAsset(null);
    setWaterUsage(null);
    setEnergyUsage(null);
    setSelectedAsset(null);
    setAssetFootprint(null);
    resetAssetOverlaps();
    store.ui.aoiPreview = null;
    store.ui.mapPreviewPin.style = null;
    store.ui.assetOverlap.currentOverlap = null;
  };

  const resetAssetOverlaps = () => {
    store.ui.assetOverlap.currentOverlap = null;
    store.ui.assetOverlap.loading = false;
    setAssetOverlap(null);
  };

  const askSwitchTeam = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div
            style={{
              backgroundColor: '#FFFFFF',
              boxShadow: '0px 0px 10px #00000029',
              borderRadius: 2,
              padding: 40,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <h1
              style={{
                fontSize: 24,
                letterSpacing: 0.4,
                marginBottom: 14,
                color: '#3B3B3B',
              }}
            >
              Switch Teams
            </h1>
            <p>Are your sure you wish to log out of the current team?</p>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginTop: 38,
              }}
            >
              <p className="popup__logout__text" style={{ marginRight: 85 }} onClick={onClose}>
                NO
              </p>
              <p className="popup__logout__text">YES</p>
            </div>
          </div>
        );
      },
    });
  };


function Iframe3DViewer({visibleLayers}) {
  // Based on raster names (old hack, todo remove eventually)
  const rastername_pointclouds = visibleLayers
    .map(layer => ([
      layer.dataset.dataset_id,
      encodeURIComponent(layer.name.replace('.tif', '')),
    ]));

  // Optimized pointclouds (proper way to do it)
  const optimized_pointclouds = visibleLayers
    .map(layer =>
      layer.dataset.optimized_pointclouds
        .filter(pc => pc.viewable)
        .sort((a, b) => a.created_at < b.created_at ? -1 : 1)
        .map(pc => ([
          layer.dataset.dataset_id,
          encodeURIComponent(pc.file_location.match(/([^/]*)$/)?.[1]),
        ]))
    )
    .flat()

  // Also a hack for some old pointclouds, todo fix eventually
  const optimized_pointclouds_nolas = optimized_pointclouds.map(v => [v[0], v[1].replace('.las', '')]);

  // Uniquify the keys
  const pointclouds = Array.from(
    new Set([].concat(optimized_pointclouds, optimized_pointclouds_nolas, rastername_pointclouds))
  );

  const combined = {
    jwt: api.currentToken,
    pointclouds: pointclouds,
  };

  return (
    <div className="connection__container">
      <iframe src={`/viewer/index.html#${encodeURIComponent(JSON.stringify(combined))}`} />
    </div>
  );
}

  const commonProps = {
    measureArea,
    measureLength,
    set3DView,
    toggleFullscreen,
    fullscreen,
    changeBasemap,
    activate3D,
    toggleCurtain,
    askSwitchTeam,
    onLayersChange,
    rangeFilters: store.ui.rangeFilters,
    onDownloadMap,
    teamInfo,
    refreshTeamInfo,
    selectedPins,
    disableMeasurement,
    loading,
    setLoading,
    showAssets,
    closeAssets,
  };

  if (loading) return <LoadingScreen />;

  updateHotlink();

  async function setTeam(team) {
    await api.call('/switchTeam', { team: team });
    await api.forceRefreshToken();
    initData();
  }

  return (
    <div style={{ height: '100%' , display: 'flex'}}>
    {/*}

      {wrongLink?.notInTeam ? <BaseModal title={<span>You are not currently in team <b>{wrongLink.team}</b>. You need to be invited to log into it</span>} //Team check
        onClickCancel={() => setWrongLink(null)} 
        leftButton='Close'
        hideRightButton={true}
        /> 
        :
        wrongLink?.team && <BaseModal title={<span>You are not logged into team <b>{wrongLink.team}</b>.</span>} 
        onClickCancel={() => setWrongLink(null)} 
        onClickRightButton={() => setTeam(wrongLink?.team)}
        rightButton='Go to team'
        rightButtonStyle='STANDARD'/> 
      }

      {(wrongLink?.team == null && wrongLink?.channel) && <BaseModal title={<span>You are not currently a member of this channel: <b>{wrongLink.channel}</b>.</span>} //Channel check
        onClickCancel={() => setWrongLink(null)} 
        leftButton='Close'
        hideRightButton={true}
        />
      }
      */}
    
      {activate3D && (<Iframe3DViewer visibleLayers={store.ui.map?.state?.mostVisibleLayers}></Iframe3DViewer>)}

      <div className={store.ui.menuOpen ? 'menu__wrapper deployed' : 'menu__wrapper'}>
        <Modal />

        {errorPopup && (
          <div className="container__popup">
            <h1 className="container__popup__header">Error</h1>
            <p>{errorPopup}</p>
            <Button 
              label="Close this message" 
              severity="danger" 
              onClick={() => setErrorPopup(null)}
              outlined />
          </div>
        )}

        {store.ui.menu.currentTab == null && (
          <Menu {...commonProps} refreshAllData={() => initData()} />
        )}

        {store.ui.menu.currentTab == 'Assets' && (
          <Assets
            onClickClose={closeAssets}
            mapJumpTo={args => store.ui.map?.state?.olmap?.jumpTo(args)}
            assets={store.data.assets}
            teamExtraData={teamInfo.extraData}
            setMonAreaAOI={setMonAreaAOI}
            setMonAreaAOIToNull={setMonAreaAOIToNull}
            selectedAssetFromMap={AssetsAsset}
            refreshAssets={refreshAssets}
            setAssetOverlap={setAssetOverlap}
            setDataframes={setDataframes}
            dataframes={dataframes}
          />
        )}

        {store.ui.menu.currentTab == 'Settings' && (
          <Settings
            onClickClose={() => CloseTabs()}
            users={teamInfo}
            refreshTeam={() => refreshTeamInfo()}
            refreshChannels={() => updateChannels()}
          />
        )}


        {(store.ui.menu.currentTab == 'DataManager') && (
          <DataManager
            onClickBack={CloseTabs}
            setSelectedAsset={showAssets}
            refreshDatasets={async () => {await refreshDatasets(); await refreshLayers();}}
            refreshAssets={async () => await refreshAssets()}
            refreshPins={async () => await refreshPins()}
            setNewViewBasedOnId={setNewViewBasedOnId}
            setClickedPin={setClickedPin}
          />
        )}

        {store.ui.menu.currentTab == 'PinUpload' && (
          <BatchPinUpload
            onClose={() => CloseTabs()}
            refreshPins={async () => await refreshPins()}
            existingPins={store.data.pins}
          ></BatchPinUpload>
        )}

        {store.ui.menu.currentTab == 'SinglePinUpload' && (
          <SinglePinUpload
            coords={addSinglePinLocation}
            onClose={() => CloseTabs()}
            refreshPins={async () => await refreshPins()}
          ></SinglePinUpload>
        )}

        {store.ui.menu.currentTab == 'SatelliteImagery' && (
          <SatelliteImagery
            refreshDatasets={async () => await refreshDatasets()}
            refreshLayers={async () => await refreshLayers()}
            onClickClose={() => CloseTabs()}
          />
        )}
        
        {store.ui.menu.currentTab == 'Processing' && (
          process.env.REACT_APP_EXPERIMENTAL_FEATURES_ENABLED ?
          <ProcessingManager onClickClose={() => CloseTabs()} /> :
          <UploadFlowWrapper onClickClose={() => CloseTabs()} />
        )}
        {store.ui.menu.currentTab == 'Help' && <Help onClickClose={() => CloseTabs()}></Help>}

      </div>

      <div className={'toolbar-map__wrapper'}>
        <DataManagerControls />

        <Toolbar {...commonProps} />

        {store.data.layers && (
          <GeoMap
            location={location}
            measure={measure}
            disableMeasurement={disableMeasurement}
            curtain={curtain}
            curtainMessage={!!curtainMessageTimeout}
            rangeFilters={store.ui.rangeFilters}
            basemap={basemap}
            pins={filteredPins}
            range={range}
            getCurrentLocation={getCurrentLocation}
            currentLocation={currentLocation}
            currentLocationRadius={currentLocationRadius}
            setLayersLoading={setLayersLoading}
            layersLoading={layersLoading}
            updateLocation={updateLocation}
            selectedPins={selectedPins}
            datasets={store.data.datasets}
            mapserverUrl={store.ui.mapserverUrl}
            refreshDatasets={async () => await refreshDatasets()}
            refreshLayers={async () => await refreshLayers()}
            refreshAssets={async () => await refreshAssets()}
            layers={store.data.layers}
            assets={store.data.assets}
            dataframes={dataframes}
            refreshPins={async () => await refreshPins()}
            aois={aois}
            monitoringAreas={monitoringAreas}
            assetFootprints={assetFootprints}
            assetOverlap={assetOverlap}
            previewLocation={store.ui.mapPreviewPin}
            waterUsage={waterUsage}
            energyUsage={energyUsage}
            selectedAsset={selectedAsset}
            aoiPreview={aoiPreview}
            hoveredOverlay={hoveredOverlay}
            showAddSinglePin={showAddSinglePin}
            showAssets={showAssets}
            clickedPin={clickedPin}
            updateHotlink={updateHotlink}
          />
        )}
      </div>

      <Slider
        layers={store.data.layers}
        rangeFilters={store.ui.rangeFilters}
        updateMinMax={updateMinMax}
        updateValuefromSlider={updateValuefromSlider}
        selectedPins={selectedPins}
        pins={store.data.pins}
        triggerSlider={store.ui.menuOpen}
      ></Slider>

      <LayersPanel setBasemap={setBasemap} />
    </div>
  );
}

export default Home;
