import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useStore, ref } from 'src/store';
import api from 'src/api';
import utils from 'src/utils';
import Analytics from 'src/Analytics';
import { transformExtent } from 'ol/proj';
import { intersects, containsCoordinate, boundingExtent } from 'ol/extent';
import BaseButton from 'src/Common/Buttons/BaseButton';
import BaseModal from 'src/Common/Modal/BaseModal';
import SelectPro from 'src/Common/SelectPro/SelectPro';
import MenuPage from 'src/Common/MenuPage';
import SliderPro from 'src/Timeslider/SliderPro';
import DataBlock from 'src/DataManager/DataBlock';
import DetailedView from 'src/DataManager/DetailedView';
import './DataManager.less';
import { ToggleButton } from 'primereact/togglebutton';
import { Dropdown } from 'primereact/dropdown';
import { InputSwitch } from 'primereact/inputswitch';
import { Button } from 'primereact/button';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { InputText } from 'primereact/inputtext';
import search from 'public/icons/search_icon.svg';


function DataManager({onClickBack, setSelectedAsset, refreshAssets, refreshPins, refreshDatasets, setClickedPin, setNewViewBasedOnId}) {
    const store = useStore()

    const view_only_user = store.session.user.role == "view_only"
    const searchBarRef = useRef(null)
    const [searchBar, setSearchBar] = useState('')
    
    const [batchDeleteMode, setBatchDeleteMode] = useState(false)
    
    const [viewModeIsGrid, setViewMode] = useState(true)
    const [selectedDataset, setSelectedDataset] = useState(null)
    
    const [showDatasets, setShowDatasets] = useState(false)
    const [showPins, setShowPins] = useState(false)
    const [showAssets, setShowAssets] = useState(false)

    const [syncViewAndList, setSyncViewAndList] = useState(true)

    const sortOptions = [
        {value: 'Name ↓', label: 'Name ↓'},
        {value: 'Name ↑', label: 'Name ↑'},
        {value: 'Date ↓', label: 'Date ↓'},
        {value: 'Date ↑', label: 'Date ↑'},
    ]
    const [currentSort, setCurrentSort] = useState(sortOptions[0].value)

    const [dateFilter, setDateFilter] = useState([0, Date.now()])

    const [shiftPressed, setShiftPressed] = useState(false);

    const [deleteProgress, setDeleteProgress] = useState(-1);
    const [deleteItemsCount, setDeleteItemsCount] = useState(-1);
    const [isRefreshing, setIsRefreshing] = useState(false);

    const [selectMode, setSelectMode] = useState(false)
    const [selectAllSet, setSelectAllSet] = useState(false)

    const [cancelDelete, setCancelDelete] = useState(false)

    const [singleDeleteMode, setSingleDeleteMode] = useState(false) //Used in detailed view for datasets only
    const [shareModal, setShareModal] = useState(false);
    const [shareLink, setShareLink] = useState('');


    function clearAll() {
        setDateFilter([0, Date.now()])
        setSearchBar('')
        store.ui.dataManager.selectedUUIDs = [];
    }

    function selectAll() {
        store.ui.dataManager.selectedUUIDs = filteredResources.map(resource => resource.uuid);
    }

    function deSelectAll() {
        store.ui.dataManager.selectedUUIDs = [];
    }

    function toggleViewModes() 
    {
        Analytics.trackClick(viewModeIsGrid ? 'data-manager-show-list-view' : 'data-manager-show-grid-view');
        setViewMode(!viewModeIsGrid);
    }

    const getCentroid = (coords) => { //I use this to find center of dataset
        let centroid = coords.reduce((accumulator, coord) => {
          return [accumulator[0] + coord[0], accumulator[1] + coord[1]];
        }, [0, 0]);
    
        centroid[0] /= coords.length;
        centroid[1] /= coords.length;
    
        return centroid;
    };

    const allResources = useMemo(() => {
        const pins = store.data.pins || [];
        const datasets = store.data.datasets || [];
        const assets = store.data.assets || [];

        return [
            ...datasets.map(d => ({
                type: 'Dataset',
                dataset: d,
                title: d.display_name || d.name || `dataset_${d.dataset_id}`,
                location: d?.optimized_rasters?.[0]?.geog?.[0],
                geog: d?.optimized_rasters?.[0]?.geog,
                preview: d.preview,
                date: new Date(d.created_at).getTime(),
                dateReadable: utils.formatDate(d.created_at),
                id: d.dataset_id,
                uuid: d.display_name + "-" + d.dataset_id,
                olLayer: store.data.layers.find(l => l.dataset.dataset_id === d.dataset_id)?.viewObject,
            })),

            ...pins.map(p => ({
                type: 'Pin',
                pin: p,
                title: p.title || `pin_${p.pin_id}`,
                location: p.geog,
                preview: p.preview,
                date: new Date(p.origin_date || p.created_at).getTime(),
                dateReadable: utils.formatDate(p.origin_date || p.created_at),
                id: p.pin_id,
                uuid: p.title + "-" + p.pin_id
            })),

            ...assets.map(a => ({
                type: 'Asset',
                asset: a,
                title: a.display_name || a.name || `asset_${a.asset_id}`,
                location: a.location,
                geog: a.geog,
                preview: a.preview,
                date: new Date(a.created_at).getTime(),
                dateReadable: utils.formatDate(a.created_at),
                id: a.asset_id,
                uuid: a.display_name + "-" + a.asset_id
            })),
        ];
    }, [store.data]);


    // Use slice to create a shallow copy of the array
    let filteredResources = allResources.slice();

    // Filter by type (if some are enabled)
    if(showDatasets || showPins || showAssets) {
        filteredResources = filteredResources.filter(r => (
            showDatasets && r.type === 'Dataset' ||
            showPins     && r.type === 'Pin'    ||
            showAssets   && r.type === 'Asset'
        ))
    }

    // Filter by map view (if enabled)
    if(syncViewAndList) {
        const viewExtent = transformExtent(
            store.ui.map.state.olmap.getView().getViewStateAndExtent().extent,
            'EPSG:3857', 'EPSG:4326'
        );
        filteredResources = filteredResources.filter(r =>
            r.geog?.length && intersects(viewExtent, boundingExtent(r.geog)) ||
            r.location?.length && containsCoordinate(viewExtent, r.location)
        );
    }

    // Filter by date (defaults to widest possible which matches all)
    filteredResources = filteredResources.filter(r =>
        dateFilter[0] <= r.date && r.date <= dateFilter[1]
    )

    // Filter by text search (empty char matches all)
    if(searchBar) {
        filteredResources = filteredResources.filter(r =>
            [r.title, r.asset?.description, r.pin?.text].some(s =>
                (s||'').toLowerCase().includes(searchBar.toLowerCase())
            )
        )
    }
    
    // Add back any checked item in selection mode (would be confusing to the user if they disappeared)
    if(selectMode && store.ui.dataManager.selectedUUIDs.length) {
        const uuids = new Set([
            ...filteredResources.map(r => r.uuid),
            ...store.ui.dataManager.selectedUUIDs,
        ])
        console.log(uuids)
        filteredResources = allResources.filter(r => uuids.has(r.uuid));
    }

    // Sort by chosen column
    if      (currentSort === 'Date ↓') filteredResources.sort((a, b) => a.date - b.date);
    else if (currentSort === 'Date ↑') filteredResources.sort((a, b) => b.date - a.date);
    else if (currentSort === 'Name ↓') filteredResources.sort((a, b) => a.title.localeCompare(b.title));
    else if (currentSort === 'Name ↑') filteredResources.sort((a, b) => b.title.localeCompare(a.title));

    // (Done)
    console.log(filteredResources);


    function handleSelectionChange (itemKey) { 
        const lastClicked = store.ui.dataManager.lastAdded;

        if (shiftPressed && lastClicked) {
            const startIndex = filteredResources.findIndex(r => r.uuid === lastClicked);
            const endIndex = filteredResources.findIndex(r => r.uuid === itemKey);
            const rangeStart = Math.min(startIndex, endIndex);
            const rangeEnd = Math.max(startIndex, endIndex);
            
            store.ui.dataManager.selectedUUIDs = [];
            for (let i = rangeStart; i <= rangeEnd; i++) {
                const rangeItemKey = filteredResources[i].uuid;
                const index = store.ui.dataManager.selectedUUIDs.indexOf(rangeItemKey);
                if (index === -1) {
                    store.ui.dataManager.selectedUUIDs.push(rangeItemKey);
                }
            }
        } else {
            const index = store.ui.dataManager.selectedUUIDs.indexOf(itemKey);
            if (index === -1) {
                store.ui.dataManager.selectedUUIDs.push(itemKey);
            } else {
                store.ui.dataManager.selectedUUIDs.splice(index, 1);
            }
        }
        
        store.ui.dataManager.lastAdded = itemKey; // Update last added if needed
    }

    function handleKeyDown (event) {
        if (event.shiftKey || event.metaKey) {
            setShiftPressed(true);
        }
        if ((event.ctrlKey || event.metaKey) && event.code === 'KeyA')
        {
            if (document.activeElement === searchBarRef.current) return;
            if (store.ui.dataManager.selectedUUIDs.length >= filteredResources.length && filteredResources.length > 0)
            {
                store.ui.dataManager.selectedUUIDs = [];
            }
            else {
                store.ui.dataManager.selectedUUIDs = [];
                store.ui.dataManager.selectedUUIDs = filteredResources.map(resource => resource.uuid);
            }
        }
    };

    const handleKeyUp = (event) => {
        if (!(event.shiftKey || event.metaKey)) {
            setShiftPressed(false);
        }
    };

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);
        document.addEventListener('keyup', handleKeyUp);
    
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
            document.removeEventListener('keyup', handleKeyUp);
        };
    });

    function delay(time) {
        return new Promise(resolve => setTimeout(resolve, time));
    }
    
    async function deleteResource(resource) {
        console.log(resource)
        if (resource.type === 'Dataset') {
            await api.call(`/dataset/remove`, {dataset_name: resource.dataset.name});
        } else if (resource.type === 'Pin') {
            await api.call(`/pin/delete`, {pin_id: resource.pin.pin_id});
        } else if (resource.type === 'Asset') {
            await api.call(`/asset/delete`, {asset_id: resource.asset.asset_id});
        }
    }

    async function deleteItems() {
        setCancelDelete(false);
        setDeleteProgress(0);
        setDeleteItemsCount(store.ui.dataManager.selectedUUIDs.length);
        var iterations = 0;

        var doRefreshAssets = false
        var doRefreshPins = false
        var doRefreshDatasets = false

        for (const element of store.ui.dataManager.selectedUUIDs) {
            if(cancelDelete) 
            {
                setDeleteProgress(-1);
                break;
            }
            setDeleteProgress(iterations);
            const resource = filteredResources.find(i => i.uuid === element);
            if (resource) {
                if(resource.type == "Pin") doRefreshPins = true;
                if(resource.type == "Asset") doRefreshAssets = true;
                if(resource.type == "Dataset") doRefreshDatasets = true;
                try {
                    await deleteResource(resource);
                } catch (error) {
                    console.error(`Failed to delete resource: ${resource.title}`, error);
                }
                await delay(100);
                iterations++;
            } else {
                console.log(`Resource with UUID ${element} not found.`);
            }
        }

        setCancelDelete(false);
        setIsRefreshing(true);
        if(doRefreshAssets) await refreshAssets();
        if(doRefreshPins) await refreshPins();
        if(doRefreshDatasets) await refreshDatasets();
        setShowDatasets(false);
        setShowPins(false);
        setShowAssets(false);
        setIsRefreshing(false);
        setDeleteProgress(-1);
        store.ui.dataManager.selectedUUIDs = [];
        setBatchDeleteMode(false);
        setDeleteItemsCount(-1);
    }

    async function deleteSingleItem() {
        setCancelDelete(false);
        setDeleteProgress(0);
        setDeleteItemsCount(store.ui.dataManager.selectedUUIDs.length);
        var iterations = 0;

        setSingleDeleteMode(false);
        setDeleteProgress(iterations);
        if (selectedDataset) {
            try {
                await deleteResource(selectedDataset);
                setSelectedDataset(null);

            } catch (error) {
                console.error(`Failed to delete resource: ${selectedDataset.title}`, error);
            }
            iterations++;
        } else {
            console.log(`Resource with UUID ${selectedDataset} not found.`);
        }

        setCancelDelete(false);
        setIsRefreshing(true);
        await refreshDatasets();
        setShowDatasets(false);
        setShowPins(false);
        setShowAssets(false);
        setIsRefreshing(false);
        setDeleteProgress(-1);
        store.ui.dataManager.selectedUUIDs = [];
        setBatchDeleteMode(false);
        setDeleteItemsCount(-1);
    }

    return (
        <div className='data_manager_container'>
            {deleteProgress >= 0 && //Deleting progress bar
                <BaseModal title={isRefreshing ? 'Refreshing Data...' : 'Deletion In Progress...'} hideButtons>
                    <div className='data_manager_delete_progress'>
                        <div className='data_manager_delete_progress_bar' style={{width: `${Math.max(1, deleteProgress * 100 / deleteItemsCount)}%`}}></div>
                        {/*<BaseButton onClick={() => {setCancelDelete(true); setDeleteProgress(0)}}>Cancel</BaseButton> */ } {/* This is causing some refresh problems, disabled for now */}
                    </div>
                </BaseModal>
            }
            {(batchDeleteMode && deleteProgress <= -1) && //Batch Delete Confirmation screen 
                <BaseModal
                title={
                    <div style={{display:'flex', gap: '10px', justifyContent: 'center'}}>
                        Deleting
                        <div style={{color:'red', fontWeight:'bold'}}>
                            {store.ui.dataManager.selectedUUIDs.length}
                        </div>
                        items
                    </div>
                }
                rightButton='Batch delete'
                onClickRightButton={() => deleteItems()}
                onClickCancel={() => setBatchDeleteMode(false)}
                >
                <div className='data_manager_batch_delete_view'>
                {store.ui.dataManager.selectedUUIDs.map(element => {
                    const resource = allResources.find(i => i.uuid === element);
                    if (resource) {
                        return (
                            <div style={{display:'flex', gap: '10px'}} key={resource.uuid}>
                                <div className={`data_type_cube ${resource.type}`}> {resource.type[0]}</div>
                                {` ${resource.uuid.split("-")[0]}`}
                            </div>
                        );
                    }
                    return null;
                })}
                </div>
                </BaseModal>
            }
            
            {singleDeleteMode && <BaseModal title='Delete Dataset?' rightButton='Delete' onClickCancel={() => setSingleDeleteMode(false)} onClickRightButton={() => deleteSingleItem()}>{"Dataset: " + selectedDataset.title}</BaseModal>}
            {selectedDataset != null ?  
                DetailedView(setSelectedDataset, selectedDataset, store, setSingleDeleteMode, shareModal, setShareModal, shareLink, setShareLink) : //Deteailed view of selected dataset

                <MenuPage //Main view

                    title={'Data Manager'}
                    isExpandButtonShowed
                    onClickBack={() => onClickBack()}
                    icon={"/icons/menu/data_manager.svg"}
                    isIconShowed={true}
                    isShowingChannel={true}
                    isArrowButtonShowed={true}
                >
                    <IconField iconPosition="right">
                        <InputIcon> 
                            <img src={search} alt="search" className="search_icon" />
                        </InputIcon>
                        <InputText                         
                            ref={searchBarRef}
                            value={searchBar}
                            onClick={e => Analytics.trackClick('data-manager-search-bar')}
                            onInput={(e) => setSearchBar(e.target.value)}
                            placeholder='Search...' 
                            style={{width: "100%"}}/>
                    </IconField>
                    <div className='data_manager_top_bar'>

                        <div className='bar_block'>
                            <div>Filter</div>
                        
                            <div className='block_controls'>
                                <ToggleButton 
                                    onLabel="Datasets" 
                                    offLabel="Datasets" 
                                    /*onIcon={ <img alt="dropdown icon" src="/icons/datamanager/roundCheck.svg" />}
                                    offIcon="pi pi-times" */
                                    checked={showDatasets} 
                                    className="round-toggle-button"
                                    onChange={(e) => setShowDatasets(e.value)} />

                                <ToggleButton 
                                    onLabel="Pins" 
                                    offLabel="Pins" 
                                    /*onIcon={ <img alt="dropdown icon" src="/icons/datamanager/roundCheck.svg" />}
                                    offIcon="pi pi-times" */
                                    checked={showPins} 
                                    className="round-toggle-button"
                                    onChange={(e) => setShowPins(e.value)} />

                                <ToggleButton 
                                    onLabel="Assets" 
                                    offLabel="Assets" 
                                    /*onIcon={ <img alt="dropdown icon" src="/icons/datamanager/roundCheck.svg" />}
                                    offIcon="pi pi-times" */
                                    checked={showAssets} 
                                    className="round-toggle-button"
                                    onChange={(e) => setShowAssets(e.value)} />
                            </div>
                        </div>
                        <div className='bar_block'>
                            <div>Sort</div>
                            <Dropdown 
                                value={currentSort} 
                                onChange={e => {
                                    Analytics.trackClick('data-manager-sort-by-dropdown');
                                    setCurrentSort(e.value)
                                }}
                                options={sortOptions} 
                                optionLabel="label"/>
                        </div>

                        <div className='bar_block'>
                            <div>View</div>
                            <div className='block_controls'>
                                <div
                                    style={{backgroundColor : viewModeIsGrid ? 'var(--gold)' : 'white', borderColor: viewModeIsGrid ? 'var(--gold)' : 'black'}}
                                    onClick={() => toggleViewModes()}
                                    className='grid_block clear_data_manager'
                                >
                                    <img
                                        style={{filter: viewModeIsGrid ? 'invert(0)' : 'invert(1)'}}
                                        className='grid_img' src='/icons/datamanager/gridView.svg'
                                    />
                                </div>
                                <div
                                    style={{backgroundColor : viewModeIsGrid ? 'white' : 'var(--gold)', borderColor: viewModeIsGrid ? 'black' : 'var(--gold)'}} 
                                    onClick={() => toggleViewModes()}
                                    className='grid_block clear_data_manager'
                                >
                                    <img
                                        style={{filter: viewModeIsGrid ? 'invert(1)' : 'invert(0)'}}
                                        className='grid_img' src='/icons/datamanager/listView.svg'
                                    />
                                </div>
                            </div>
                        </div>

                        <div className='bar_block'>
                            <div>Select</div>
                            <div className='block_controls'>
                                <InputSwitch checked={selectMode} onChange={(e) => setSelectMode(e.value)} />
                            </div>
                        </div>
                    </div>

                    <div className={`data_manager_content ${selectMode ? 'select' : ''}`}>                      
                    {filteredResources.map((resource, index) => (
                        <DataBlock
                            key={resource.uuid}
                            handleSelectionChange={handleSelectionChange}
                            setSelectedDataset={setSelectedDataset}
                            setSelectedAsset={setSelectedAsset}
                            selectMode={selectMode}
                            type={resource.type}
                            viewModeIsGrid={viewModeIsGrid}
                            resource={resource}
                            filter={syncViewAndList}
                            setNewViewBasedOnId={setNewViewBasedOnId}
                        />
                    ))}
                </div>

                    {selectMode && (
                        <div className='data_manager_select_section'> 
                            <div className='data_manager_selected_count'>{store.ui.dataManager.selectedUUIDs.length} selected</div>
                            <div className='data_manager_select_actions'>
                                <Button 
                                    label={selectAllSet ? "Deselect All" : "Select All"} 
                                    onClick={() => {setSelectAllSet(!selectAllSet); !selectAllSet ? selectAll() : deSelectAll()}} 
                                    outlined/>

                                <Button 
                                    label="Delete" 
                                    severity="danger"
                                    disabled={store.session.user.role == "view_only" || store.ui.dataManager.selectedUUIDs.length <= 0}
                                    title={view_only_user ? "Only Admins Can Delete" : "Delete Selected Data"} 
                                    onClick={() => setBatchDeleteMode(true)}
                                    />
                            </div>
                        </div>
                    )}

                    {allResources.length > 0 && (
                        <div className="timeline_data_manager" onClick={e => Analytics.trackClick('data-manager-time-slider')}>
                            <SliderPro
                                ticks={filteredResources.map(r => r.date)}
                                range={
                                    (   // Preserve global time range of all resources even if showing fewer filtered ticks
                                        a => ([Math.min(...a), Math.max(...a)])
                                    )(  allResources.map(r => r.date).filter(r=>r)  )
                                }
                                values={dateFilter}
                                updateValue={setDateFilter}
                            />
                        </div>
                    )}

                    <div className='sync_view_and_map_container'>
                        Sync view & list
                        <InputSwitch 
                            checked={syncViewAndList} 
                            onChange={(e) => {
                                Analytics.trackClick('data-manager-filter-screen-dropdown');
                                setSyncViewAndList(e.value)
                            }} />
                    </div>
                </MenuPage>}

            {/*<div className='sidetrigger'> </div>*/}
        </div>
    );
}

export default DataManager;
