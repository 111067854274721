import React from 'react';
import { useStore } from 'src/store';
import { Button } from 'primereact/button';
import { ref } from 'valtio';

export default function DataManagerControls() {
  const store = useStore();
  return (
    <>
      {store.ui.dataManager.hiddenUUIDs.length > 0 && (
        <div className='show_hidden_datasets_container' >
            Hidden datasets
            <Button
                link
                label="Show all"
                onClick={() => {
                    store.data.layers.forEach(l => l.viewObject.setVisible(true))
                    store.ui.dataManager.hiddenUUIDs = []
                }}
            />
        </div>
      )}
    </>
  );
}