import React from 'react';
import { Slider, Rail, Handles, Tracks, Ticks } from 'react-compound-slider';
import Track from './Track';
import Tick from './Tick';
import Handle from './Handle';
import SliderRail from './SliderRail';

export default function SliderPro({ range, values, ticks, updateValue }) {
  return (
    <div className="slider-container">
      <Slider
        mode={2}
        step={1}
        domain={range}
        values={values}
        onChange={e => (e[0] !== values[0] || e[1] !== values[1]) && updateValue(e)}
      >
        <Rail>{({ getRailProps }) => <SliderRail getRailProps={getRailProps} />}</Rail>
        <Handles>
          {({ handles, getHandleProps }) => (
            <div className="slider-handles">
              {handles.map(handle => (
                <Handle
                  key={handle.id}
                  handle={handle}
                  domain={range}
                  getHandleProps={getHandleProps}
                />
              ))}
            </div>
          )}
        </Handles>
        <Tracks left={false} right={false}>
          {({ tracks, getTrackProps }) => (
            <div className="slider-tracks">
              {tracks.map(({ id, source, target }) => (
                <Track key={id} source={source} target={target} getTrackProps={getTrackProps} />
              ))}
            </div>
          )}
        </Tracks>
        <Ticks values={ticks}>
          {({ ticks }) => (
            <div className="slider-ticks">
              {ticks.map((tick, index) => (
                <Tick key={index} tick={tick} count={ticks.length} />
              ))}
            </div>
          )}
        </Ticks>
      </Slider>
    </div>
  );
};