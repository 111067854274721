import './PrimeReactTheme/theme.css';
import React, { useState } from 'react';
import './App.less';
import { useLocation } from 'react-router-dom';
import Home from 'src/Home';
import Login from 'src/Login';
import SwitchTeam from 'src/SwitchTeam';
import LoadingScreen from 'src/LoadingScreen';

import Analytics from 'src/Analytics';

import { useAuth0 } from '@auth0/auth0-react';
import api from 'src/api';
import { useStore } from 'src/store';

function App() {
  const [stage, setStage] = useState(null); // null -> fetching-token -> success / failure
  const [error, setError] = useState(null);
  const store = useStore();
  api.auth0 = useAuth0();

  // Fix for Windows 10 UI scaling on small laptops
  if (navigator.platform == 'Win32' && devicePixelRatio > 1 && devicePixelRatio < 2) {
    document.body.style.scale = 1 / devicePixelRatio;
    document.body.style.height = 100 * devicePixelRatio + 'vh';
    document.body.style.width = 100 * devicePixelRatio + 'vw';
  }

  // On first load, attempt to get token
  if (stage === null) {
    setStage('fetching-token');
    setError(null);
    /*
    api.auth0 = new Auth0Client({
      domain: process.env.REACT_APP_AUTH0_CUSTOM_DOMAIN,
      clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
      authorizationParams: {
        redirect_uri: process.env.REACT_APP_AUTH0_CALLBACK_URL,
        audience: `https://${process.env.REACT_APP_AUTH0_TENANT_DOMAIN}/api/v2/`,
        scope: 'openid profile email update:current_user_metadata',
      },
    })
*/
    const path = useLocation().pathname;
    if (path.startsWith('/shared/')) {
      const hash = path.split('/')[2];

      api
        .obtainTokenForSharingLink(hash)
        .then(token => {
          try {
            api.receiveNewToken(token);
            setStage('success');
          } catch (e) {
            setError(e);
            setStage('failure');
          }
        })
        .catch(e => {
          setError(e);
          setStage('failure');
        });
    } else {
      api.auth0
        .getAccessTokenSilently()
        .then(token => {
          try {
            api.receiveNewToken(token);
            setStage('success');
          } catch (e) {
            setError(e);
            setStage('failure');
          }
        })
        .catch(e => {
          setError(e);
          setStage('failure');
        });
    }
  }

  if (stage === null || stage === 'fetching-token') {
    return (
      <main className="App">
        <div className="App__content">
          <LoadingScreen />
        </div>
      </main>
    );
  }

  if (stage === 'success' && store.session.token && !store.session.user.email) {
    return (
      <main className="App">
        <div className="App__content">
          <SwitchTeam />
        </div>
        <Analytics />
      </main>
    );
  }

  if (stage === 'success' && store.session.token && store.session.user.email) {
    return (
      <main className="App">
        <div className="App__content">
          <Home />
        </div>
        <Analytics />
      </main>
    );
  }

  // Default page (including stage=failure)
  return (
    <main className="App">
      <div className="App__content">
        {/*path.startsWith('/shared/') && (
          <div className="sharing-link-invalid">
            <p>This sharing link is expired or invalid</p>
            <img src="/SVARMI-Logo_Datact-Horiz.svg" />
          </div>
        )*/}
        <Login
          error={error || api.auth0.error}
          doLogin={async () => {
            try {
              await api.auth0.logout();
            } catch (e) {
              console.error(e);
            }
            await api.auth0.loginWithRedirect({ appState: { returnTo: '/' + window.location.hash } });
          }}
        />
      </div>
      <Analytics />
    </main>
  );
}

export default App;
